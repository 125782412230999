import {
  apiPostToken,
  apiFirstPage,
  apiTotalPages,
  apiTargetPage,
} from "./constants";

export async function getApiTargetPage(token, number) {
  const response = await fetch(apiTargetPage + number, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;",
      Authorization: "JToken " + token,
    },
  });
  const body = await response.json();
  return body;
}

export async function getApiTotalPages(token) {
  const response = await fetch(apiTotalPages, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;",
      Authorization: "JToken " + token,
    },
  });
  const body = await response.json();
  return body;
}

export async function getApiFirstPage(token) {
  const response = await fetch(apiFirstPage, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;",
      Authorization: "JToken " + token,
    },
  });
  const body = await response.json();
  return body;
}

export async function authUser(login, pass) {
  const response = await fetch(apiPostToken, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;",
    },
    body: JSON.stringify({
      username: login,
      password: pass,
    }),
  });

  const responseBody = await response.json();
  return responseBody;
}
