import React from "react";
import "./ItemPhoto.css";

export default function ItemPhoto({
  targetFhoto,
  classItemPhoto,
  setClassItemPhoto,
}) {
  return (
    <div className={classItemPhoto}>
      <div className="item-photo__content">
        <div className="item-fhoto__content__box-btn-close">
          <button
            type="button"
            onClick={() => setClassItemPhoto("item-none")}
            className="item-fhoto__content__box-btn-close__btn-close"
          >
            &#10006;
          </button>
        </div>
        <img className="item-fhoto__content__image" src={targetFhoto} alt="" />
      </div>
    </div>
  );
}
