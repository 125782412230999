import React, { useState } from "react";
import "./BoxVideo.css";

export default function BoxVideo({ getTargetVideo }) {
  const [targetCamera, setTargetCamera] = useState("");

  const [checked, setChecked] = useState(false);
  const createLinkVideo = () => {
    if (checked === false) {
      getTargetVideo(targetCamera, "2");
    }
    if (checked === true) {
      getTargetVideo(targetCamera, "1");
    }
  };

  return (
    <div className="box-video">
      <p className="box-table-title__text">
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        HD
      </p>
      <label>
        <select
          onChange={(e) => setTargetCamera(e.target.value)}
          value={targetCamera}
          required
        >
          <option value="" disabled>
            Выберите камеру
          </option>
          <option value="1">камера №1</option>
          <option value="2">камера №2</option>
        </select>
      </label>
      <button
        className="box-table-title__btn"
        type="button"
        onClick={createLinkVideo}
        disabled={targetCamera === ""}
      >
        просмотр
      </button>
    </div>
  );
}
