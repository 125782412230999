import React, { Component } from "react";
import "./Authorization.css";
import { authUser } from "../../api";
import { getApiFirstPage } from "../../api";

export default class Authorization extends Component {
  state = {
    login: "",
    password: "",
    error: "",
  };

  loginInputAuthorization = (event) => {
    if (event.target.value.length < 20) {
      this.setState({ login: event.target.value });
      this.setState({ error: "" });
    }
  };
  passInputAuthorization = (event) => {
    if (event.target.value.length < 20) {
      this.setState({ password: event.target.value });
      this.setState({ error: "" });
    }
  };

  handleSubmitLogin = (evt) => {
    const { login, password } = this.state;
    evt.preventDefault();
    authUser(login, password)
      .then((resBody) => {
        if (resBody.token !== undefined) {
          localStorage.setItem("token-omega", JSON.stringify(resBody.token));
          localStorage.setItem("page-omega", JSON.stringify(1));
          getApiFirstPage(JSON.parse(localStorage.getItem("token-omega"))).then(
            (resBody) => {
              this.props.setList(resBody);
              this.props.setTargetComponent("table");
            }
          );
        } else {
          return this.setState({ error: resBody.non_field_errors[0] });
        }
      })
      .catch((err) => console.log(`Ошибка: ${err}`));
  };

  render() {
    return (
      <div className="auth-registration-main-box">
        <div className="auth-window">
          <form
            className="authorization-user"
            onSubmit={this.handleSubmitLogin}
          >
            <h2 className="auth-title">Авторизация</h2>
            <div className="auth-login">
              <label>
                <input
                  className="auth-and-reg-input"
                  onChange={this.loginInputAuthorization}
                  type="text"
                  value={this.state.login}
                  placeholder="Имя пользователя"
                  required
                />
              </label>
            </div>

            <div className="auth-pass">
              <label>
                <input
                  className="auth-and-reg-input"
                  onChange={this.passInputAuthorization}
                  value={this.state.password}
                  placeholder="Пароль"
                  type="password"
                  required
                />
              </label>
            </div>

            <div className="box-btn-login">
              <button className="btn-registration-login" type="submit">
                Войти
              </button>
            </div>
            <p className="box-message-error">{this.state.error}</p>
          </form>
        </div>
      </div>
    );
  }
}
