import React, { useState } from "react";
import "./ItemTable.css";

export default function ItemTable({ date, numberCar, numberPhoto, getTargetFhoto }) {
  const [itemDate] = useState(date);
  const [itemNumberCar] = useState(numberCar);
  const [itemNumberPhoto] = useState(numberPhoto);
  

  return (
    <tr className="table-item__tr">
      <td className="table-item__td">{itemDate}</td>
      <td className="table-item__td">{itemNumberCar}</td>
      <td className="table-item__td">
        <button className="table-item__td__button" type="button" onClick={() => getTargetFhoto(itemNumberPhoto)}>
          Фото
        </button>
      </td>
    </tr>
  );
}
