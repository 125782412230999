import React, { useState, useEffect } from "react";
import "./MainPage.css";
import Authorization from "../components/Authorization/Authorization";
import Table from "../components/Table/Table";
import { getApiTargetPage } from "../api";
import { getApiFirstPage } from "../api";
export default function MainPage() {
  const [list, setList] = useState([]);
  const [targetComponent, setTargetComponent] = useState("authorization");

  useEffect(() => renderPage(), [targetComponent]);

  const renderPage = () => {
    if (
      localStorage.getItem("token-omega") !== null &&
      localStorage.getItem("page-omega") !== null
    ) {
      getApiTargetPage(
        JSON.parse(localStorage.getItem("token-omega")),
        JSON.parse(localStorage.getItem("page-omega"))
      )
        .then((resBody) => {
          setList(resBody, setTargetComponent("table"));
        })
        .catch((resBody) => {
          return (
            console.log(resBody),
            window.location.reload(),
            localStorage.removeItem("token-omega"),
            localStorage.removeItem("page-omega")
          );
        });
    }
    if (
      localStorage.getItem("token-omega") !== null &&
      localStorage.getItem("page-omega") === null
    ) {
      getApiFirstPage(JSON.parse(localStorage.getItem("token-omega"))).then(
        (resBody) => {
          setList(resBody, setTargetComponent("table"));
        }
      );
    }
    if (
      localStorage.getItem("token-omega") === null &&
      localStorage.getItem("page-omega") === null
    ) {
      setTargetComponent("authorization");
    }
  };

  const exit = () => {
    setTargetComponent("authorization");
    localStorage.removeItem("token-omega");
    localStorage.removeItem("page-omega");
  };

  return (
    <div className="main-page">
      {targetComponent === "table" && (
        <Table list={list} exit={exit} setList={setList} />
      )}
      {targetComponent === "authorization" && (
        <Authorization
          setTargetComponent={setTargetComponent}
          setList={setList}
        />
      )}
    </div>
  );
}
