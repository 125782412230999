import React, { useState, useEffect } from "react";
import "./Table.css";
import ItemTable from "./ItemTable/ItemTable";
import ItemPhoto from "../ItemPhoto/ItemPhoto";
import { getApiTotalPages } from "../../api";
import { apiMain } from "../../constants";
import BoxVideo from "../BoxVideo/BoxVideo";

export default function Table({ list, exit }) {
  const [totalPages, setTotalPages] = useState("");
  const [targetFhoto, setTargetFhoto] = useState("");
  const [classItemPhoto, setClassItemPhoto] = useState("item-none");
  //----------------BoxVideo--------------------------------

  //--------------------------------------------------------

  const getTargetFhoto = (link) => {
    setClassItemPhoto("item-photo");
    setTargetFhoto(apiMain + link);
  };

  const getTargetVideo = (cam, hd) => {
    setClassItemPhoto("item-photo");
    setTargetFhoto(
      `http://nikproject.ru/live/?cam=${cam}&hd=${hd}&secret=Uzd$n1t@E%y1mGo0p9I~hVsC8f}f0~1{rUurZbXG`
    );
  };
  //-----------------
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem("page-omega")) !== null
      ? +JSON.parse(localStorage.getItem("page-omega"))
      : 1
  );
  useEffect(
    () =>
      getApiTotalPages(JSON.parse(localStorage.getItem("token-omega"))).then(
        (resBody) => setTotalPages(resBody.pages_count)
      ),
    [list]
  );

  const [inputPage, setInputPage] = useState(
    JSON.parse(localStorage.getItem("page-omega")) !== null
      ? +JSON.parse(localStorage.getItem("page-omega"))
      : 1
  );

  const changePage = (direction) => {
    setPage((state) => {
      // move forward
      if (direction) {
        // if page is the last page, do nothing
        if (state === totalPages) {
          return localStorage.setItem("page-omega", JSON.stringify(state));
        }
        return (
          localStorage.setItem("page-omega", JSON.stringify(state + 1)),
          window.location.reload()
        );
        // go back
      } else {
        // if page is the first page, do nothing
        if (state === 1) {
          return localStorage.setItem("page-omega", JSON.stringify(state));
        }
        return (
          localStorage.setItem("page-omega", JSON.stringify(state - 1)),
          window.location.reload()
        );
      }
    });
  };

  const result = list.map((item, index) => {
    return (
      <ItemTable
        key={index}
        date={item.date}
        id={item.id}
        numberCar={item.mark}
        numberPhoto={item.photo}
        getTargetFhoto={getTargetFhoto}
      />
    );
  });

  const changeInputPage = (event) => {
    event.preventDefault();
    if (event.target.value.length < 10 && +event.target.value <= totalPages) {
      setInputPage(event.target.value);
    }
  };
  const handleKeyPress = (event) => {
    localStorage.setItem("page-omega", JSON.stringify(inputPage));
  };
  return (
    <form className="form__table">
      <table className="box-table">
        <caption className="box-table-title">
          <div className="box-btn">
            <BoxVideo getTargetVideo={getTargetVideo} />
            <button
              className="box-table-title__btn"
              onClick={() => exit()}
              type="button"
            >
              выйти
            </button>
          </div>
          <div className="pagination">
            <p className="text">
              {page}/{totalPages}
            </p>
            <button
              onClick={() => changePage(false)}
              className={`page ${page === 1 && "disabled"}`}
              type="button"
              disabled={page === 1}
            >
              &larr;
            </button>

            <label>
              <input
                className="input-change-page"
                onChange={changeInputPage}
                type="number"
                value={inputPage}
                placeholder=""
                required
                step="1"
                min="1"
                max={totalPages}
                onKeyPress={handleKeyPress}
              />
            </label>

            <button
              onClick={() => changePage(true)}
              className={`page ${page === totalPages && "disabled"}`}
              type="button"
              disabled={page === totalPages}
            >
              &rarr;
            </button>
          </div>
          <div>
            <button
              className="box-table-title__btn"
              onClick={() => handleKeyPress()}
            >
              поиск
            </button>
          </div>
        </caption>
        <thead>
          <tr>
            <th className="table-item__th">Дата:</th>
            <th className="table-item__th">Номер машины:</th>
            <th className="table-item__th">Камера:</th>
          </tr>
        </thead>
        <tbody>{result}</tbody>
      </table>
      <ItemPhoto
        classItemPhoto={classItemPhoto}
        targetFhoto={targetFhoto}
        setClassItemPhoto={setClassItemPhoto}
      />
    </form>
  );
}
